import React, { Fragment } from "react"

export interface MultilineTextProps {
  text: string[]
}
export const MultilineText = ({ text }: MultilineTextProps) => (
  <>
    {[
      ...text.slice(0, 1),
      ...text.slice(1).flatMap((line, index) => [
        <Fragment key={index}>
          <span className="multiline"></span>
          <br className="multiline" />
        </Fragment>,
        line,
      ]),
    ]}
  </>
)
